import FooterText from "./Footer-text";
import classes from "./Footer.module.scss";

const Footer = function () {
  return (
    <section className={classes["footer-section"]}>
      <h3>Thanks for your Visiting</h3>
      <FooterText />
    </section>
  );
};

export default Footer;
