import classes from "./Footer-text.module.scss";

const FooterText = function () {
  return (
    <div className={classes["footer-text"]}>
      <div>
        <h2>Check Out My</h2>
        <a
          href="https://drive.google.com/file/d/1rANMbY3RHLG1QzBYa1_yYJZZ1nHzLxQh/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className={classes["footer-contact-button"]}
        >
          Resume
        </a>
      </div>
      <div>
        <h2>Get in Touch</h2>
        <a
          href="mailto:fernanda.pintop@gmail.com"
          className={classes["footer-contact-button"]}
          target="_blank"
          rel="noreferrer"
        >
          SayHi
        </a>
      </div>
    </div>
  );
};

export default FooterText;
