import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const AnimatedText = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {isVisible &&
        text.split("").map((char, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: index * 0.1 }}
          >
            {char}
          </motion.span>
        ))}
    </motion.span>
  );
};

export default AnimatedText;
