import classes from "./Project.item.module.scss";
import arrowSvg from "../assets/svg-icons/arrow-up-right-svgrepo-com.svg";
import AnimateImg from "./AnimateImg";

const ProjectItem = function (props) {
  return (
    <div className={classes["project-item"]}>
      <AnimateImg>
        <div className={classes["project-item-img-container"]}>
          <a href={props.url} target="_blank" rel="noreferrer">
            <img
              alt={props.name}
              src={props.img}
              className={classes["img-project"]}
              loading="lazy"
            ></img>
          </a>
        </div>
      </AnimateImg>
      <div className={classes["project-item-text"]}>
        <h2>{props.name}</h2>
        <h3>{props.subtitle}</h3>
        <p>{props.description}</p>
        <a
          href={props.url}
          target="_blank"
          rel="noreferrer"
          className={classes.btnViewMore}
        >
          View More
          <div className={classes["btnViewMore-box-icon"]}>
            <img
              src={arrowSvg}
              alt="Arrow Icon"
              className={classes["btnViewMore-icon"]}
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProjectItem;
