import classes from "./Navbar.module.scss";
import navbarLogo from "../assets/portfolio-logo.png";
import { Link } from "react-scroll";

const NavBar = function () {
  return (
    <div className={classes["navbar-container"]}>
      <Link to="home-section" smooth={true} duration={500}>
        <img
          className={classes["navbar-img"]}
          src={navbarLogo}
          alt="portfolio logo Fp"
        ></img>
      </Link>

      <nav className={classes["navbar-text"]}>
        <Link
          className={`${classes["navbar-link"]} ${classes["navbar-item"]}`}
          to="home-section"
          smooth={true}
          duration={500}
        >
          About me
        </Link>
        <Link
          className={`${classes["navbar-link"]} ${classes["navbar-item"]}`}
          to="project-section"
          smooth={true}
          duration={500}
        >
          Projects
        </Link>
        <Link
          className={`${classes["navbar-link"]} ${classes["navbar-item"]}`}
          to="skills-section"
          smooth={true}
          duration={800}
        >
          Skilss
        </Link>
        <a
          className={`${classes["navbar-button"]} ${classes["navbar-item"]}`}
          href="mailto:fernanda.pintop@gmail.com"
        >
          Lets Connect
        </a>
      </nav>
    </div>
  );
};

export default NavBar;
