import classes from "./Footer-link.module.scss";

const FooterLink = function () {
  return (
    <div className={classes["footer-links"]}>
      <a
        href="https://www.linkedin.com/in/maria-fernanda-pinto-pe%C3%B1a-201a791bb/"
        target="_blank"
        rel="noreferrer"
      >
        Linkedin
      </a>
      <p>© 2023 Fernanda Pinto All rights reserved.</p>
      <a
        href="https://github.com/fernandapintop"
        target="_blank"
        rel="noreferrer"
      >
        Git Hub
      </a>
    </div>
  );
};

export default FooterLink;
