import classes from "./Skills.module.scss";
import arrowSvg from "../assets/svg-icons/Arrow 2.svg";
import html5Svg from "../assets/svg-icons/icons8-html.svg";
import cssSvg from "../assets/svg-icons/icons8-css.svg";
import jsSvg from "../assets/svg-icons/icons8-javascript.svg";
import sassSvg from "../assets/svg-icons/icons8-sass.svg";
import reactSvg from "../assets/svg-icons/icons8-react.svg";
import gitSvg from "../assets/svg-icons/icons8-git.svg";
import vscodeSvg from "../assets/svg-icons/icons8-visual-studio-code.svg";
import typeSvg from "../assets/svg-icons/icons8-typescript.svg";
import dockerSvg from "../assets/svg-icons/docker-svgrepo-com.svg";
import figma from "../assets/svg-icons/icons8-figma.svg";
import nodeSvg from "../assets/svg-icons/node-dot-js.svg";
import responsiSvg from "../assets/svg-icons/responsive-svgrepo-com.svg";

const Skills = function () {
  return (
    <section className={classes["skills-container"]} id="skills-section">
      <div className={classes["skills-text-container"]}>
        <p>SKILLS & TOOLS</p>
        <div className={classes["skills-text"]}>
          <h2>My Toolbox & Things I Can Do</h2>
          <img
            className={classes["skills-text-svg"]}
            src={arrowSvg}
            alt="arrow icon"
          />
        </div>
      </div>
      <div className={classes.lineaPloma}></div>
      <div className={classes["skills-svg-container"]}>
        <img src={html5Svg} alt="html icon" />
        <img src={cssSvg} alt="css icon" />
        <img src={jsSvg} alt="js icon" />
        <img src={nodeSvg} alt="node icon" />
        <img src={reactSvg} alt="react icon" />
        <img src={gitSvg} alt="git icon" />
        <img src={vscodeSvg} alt="vs code icon" />
        <img src={typeSvg} alt="type icon" />
        <img src={dockerSvg} alt="docker icon" />
        <img src={figma} alt="figma icon" />
        <img src={responsiSvg} alt="responsive icon" />
        <img src={sassSvg} alt="sass icon" />
      </div>
    </section>
  );
};

export default Skills;
