import classes from "./Pagewrapper.module.scss";
import React from "react";

const PageWrapper = function ({ children }) {
  return (
    <div className={classes["page-wrapper"]} style={{ opacity: 1 }}>
      {children}
    </div>
  );
};

export default PageWrapper;
