import ProjectItem from "./Project.item";
import classes from "./Projects.module.scss";
import natoursimg from "../assets/natours-portfolio-project-optimizada.png";
import bankistimg from "../assets/bankist-portfolio-project-optimizada.png";
import gameimg from "../assets/game-portfolio-project-optimizada.png";
import nexterimg from "../assets/nexter-portfolio-project-optimizado.png";
import brazucaimg from "../assets/brazuca-portfolio-project-optimizado.png";
import forkifyimg from "../assets/forkify-portolio-optimizada.png";
import arrowSvg from "../assets/svg-icons/Arrow 2.svg";
import glowVibeImg from "../assets/Glowvibe-mockup.png";

const dataProyect = [
  {
    id: "p1",
    name: "GlowVibe Ecomemrce",
    subtitle: "Online store / Skin Care",
    description:
      "App where the user can register, log in, view products, add items to their cart and make purchases, developed for the company GlowVibe build in React and NodeJS  for the API",
    img: glowVibeImg,
    url: "https://glowvibebo.onrender.com/",
  },
  {
    id: "p2",
    name: "Natours Landing Page",
    subtitle: "Get a perfect tour",
    description:
      "With this app, you can easily log the distance covered, the time spent, and even mark your workout location on a map for a detailed record of your journey.",
    img: natoursimg,
    url: "https://natuours-site.netlify.app/",
  },
  {
    id: "p3",
    name: "Bankist Landing Page",
    subtitle: "Made simple online",
    description:
      "Discover our modern online banking platform – user-friendly design, packed with useful features.",
    img: bankistimg,
    url: "https://bankist-appfpp.netlify.app/",
  },
  {
    id: "p4",
    name: "Numbers Game",
    subtitle: "Have some fun!",
    description:
      "Presenting two engaging games for your enjoyment. 'Guess Number' tests your deduction skills, while 'Pig Game' is all about luck. Are you ready for some gaming fun?",
    img: gameimg,
    url: "https://piggame-fp.netlify.app/",
  },
  {
    id: "p5",
    name: "Nexter Real State",
    subtitle: "Dream homes available",
    description:
      "This landing page designed for a real estate company that shows all the properties and sellers in a harmonious way.",
    img: nexterimg,
    url: "https://nexter-fpp.netlify.app/",
  },
  {
    id: "p6",
    name: "Brazuca Imports Ecommerce",
    subtitle: "Coming Soon",
    description:
      "In development process for a Bolivian company of products imported from Brazil, with various sections and creation of users",
    img: brazucaimg,
    url: "",
  },
  {
    id: "p7",
    name: "Forkify App",
    subtitle: "Tracking daily workouts",
    description:
      "Forkify is your go-to app for discovering and trying out exciting recipes. With a user-friendly interface, it makes searching for recipes and cooking inspiration a breeze.",
    img: forkifyimg,
    url: "https://forkify-project-ferpinto.netlify.app/",
  },
];

const Projects = function () {
  return (
    <section className={classes["section-project"]} id="project-section">
      <div className={classes["section-project-text-container"]}>
        <h2 className={classes["section-project-title"]}>
          Checkout my projects
        </h2>
        <img
          className={classes["section-project-svg"]}
          src={arrowSvg}
          alt="Mi SVG"
        />
      </div>
      <div className={classes.lineaPloma}></div>
      {dataProyect.map((project) => (
        <ProjectItem
          key={project.id}
          name={project.name}
          subtitle={project.subtitle}
          description={project.description}
          img={project.img}
          url={project.url}
        />
      ))}
    </section>
  );
};

export default Projects;
