import { Fragment } from "react";
import NavBar from "./components/Navbar";
import Home from "./components/Home";
import PageWrapper from "./components/UI/Pagewrapper";
import MainWrapper from "./components/UI/Mainwrapper";
import Projects from "./components/Projects";
import Skills from "./components/Skilss";
import Footer from "./components/Footer";
import FooterLink from "./components/Footer-links";

function App() {
  return (
    <Fragment>
      <NavBar />
      <PageWrapper>
        <MainWrapper>
          <Home />
          <Projects />
          <Skills />
          <Footer />
          <FooterLink />
        </MainWrapper>
      </PageWrapper>
    </Fragment>
  );
}

export default App;
