import { motion, useInView } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import classes from "../components/Project.item.module.scss";

const AnimateImg = function ({ children }) {
  const ref = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView && !isAnimated) {
      // Si el elemento está en vista y aún no se ha animado, animarlo.
      setIsAnimated(true);
    }
  }, [isInView, isAnimated]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.1 }}
      animate={{
        opacity: isAnimated ? 1 : 0,
        scale: isAnimated ? 1 : 0.5,
      }}
      transition={{
        duration: 0.7,
        ease: "easeInOut",
      }}
      className={classes["img-animation"]}
    >
      {children}
    </motion.div>
  );
};

export default AnimateImg;
