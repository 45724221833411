import classes from "./Home.module.scss";
import homeImage from "../assets/portfolio-home-optimizado.png";
import AnimatedText from "./UI/AnimateText";

const Home = function () {
  const text = "A Frontend Developer";
  return (
    <div className={classes["home-container"]} id="home-section">
      <div className={classes["home-text"]}>
        <h1 className={classes["home-text-item"]}>
          <span className={classes["span-I"]}>Hi 👋</span>
          <br />{" "}
          <span className={classes["span-name"]}>I'm Fernanda Pinto</span>
          <br />
          <span className={classes["span-frontEnd"]}>
            {" "}
            <AnimatedText text={text} />
          </span>
        </h1>
      </div>
      <p className={classes["home-parrafo"]}>
        Industrial Engineer with a passion for Front-End Development, proficient
        in Responsive Web Design, Web Content Writing, and various programming
        languages. Committed to continuous learning.
      </p>
      <div className={classes["home-img-container"]}>
        <img
          src={homeImage}
          className={classes["home-img"]}
          alt="principal img home"
        ></img>
      </div>
    </div>
  );
};

export default Home;
